<template>
  <div class="container-fluid">
    <div class="page-title-box">
      <bread-crumb
        :breadcrumbs="[
          { label: 'Отпуска', routeName: 'Vacation' },
          { label: 'Создание отпуска' },
        ]"
      ></bread-crumb>
      <h4 class="page-title">Создание отпуска</h4>
    </div>
    <div class="card">
      <div class="card-body">
        <Form
          :service="service"
          redirectAfterSave="VacationShow"
          :actions="[{ action: 'save', to: (data) => '/vacation/' + data.id }]"
        >
          <FormField
            label="Сотрудник"
            :service="service"
            field="userId"
            :options="userList"
            type="select"
          />
          <FormField
            :service="service"
            field="dtStart"
            label="Начало отпуска"
            type="date"
          />
          <FormField
            :service="service"
            field="dtEnd"
            label="Конец отпуска"
            type="date"
          />
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import Form from "@/components/ui/form/Form.vue";
import BreadCrumb from "/src/components/ui/BreadCrumb";
import FormField from "@/components/ui/form/FormField.vue";
import SpeedDialBtn from "@/components/ui/SpeedDialBtn.vue";
import VacationService from "../services/VacationService";
import UserService from "@/modules/user/services/UserService";

export default {
  components: {
    BreadCrumb,
    SpeedDialBtn,
    Form,
    FormField,
  },
  data() {
    return {
      service: new VacationService(),
      userService: new UserService(),
      userList: {},
    };
  },
  async created() {
    this.userList = await this.userService.allList();
  },
};
</script>

